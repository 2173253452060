import { envConfig } from '@infrastructure/envConfig'

export class TreatmentsUrlProvider {
    baseUrl = `${envConfig.API_URL}`

    treatmentDetailsUrl = id => `${this.baseUrl}/treatments/${id}`

    treatmentsUrl = clinicId => `${this.baseUrl}/clinics/${clinicId}/treatments`

    clinicCategoriesUrl = clinicId =>
        `${this.baseUrl}/clinics/${clinicId}/categories`

    treatmentCategoryUrl = id => `${this.baseUrl}/treatment-categories/${id}`
}
