import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { getPractitioners } from '@store/reducers/practitioners'
import { useParams } from 'react-router'
import { SidebarPageHeader } from '@shared/components/Sidebar/SidebarPageHeader'
import { getRouteWithId, getRouteWithPractitionerId } from '@utils/routeUtils'
import {
    TableContainer,
    TableContentContainer,
} from '@shared/components/Table/styledComponents'
import { Row } from '@shared/components/Table/Raw'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { translate } from '@utils/translationUtils'
import { Routes } from '@shared/constants/routes'
import Box from '@material-ui/core/Box'
import { Title } from '@containers/clinics/components/Title'
import { StyledTitle } from '@containers/clinics/styledComponents'
import { WithStatusDot } from '@shared/components/StatusDot/WithStatusDot'

const Practitioners = ({ store, dispatch }) => {
    const { id } = useParams()
    const { practitioners, loading } = store.practitioners

    useEffect(() => {
        dispatch(getPractitioners(id))
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <SidebarPageHeader
                title={translate('practitioner.practitioners')}
                navigateTo={getRouteWithId(Routes.practitionersCreate, id)}
                navBtnText={translate('addNew')}
            />

            {!!practitioners.length && (
                <TableContainer>
                    <TableContentContainer>
                        {practitioners.map(practitioner => {
                            const {
                                name,
                                title,
                                image,
                                status,
                            } = practitioner

                            return (
                                <Box key={practitioner.id} width="100%">
                                    <Row
                                        firstCellContent={
                                            <WithStatusDot status={status}>
                                                <Title
                                                    text={name}
                                                    imageSrc={image}
                                                    isRoundImage
                                                    isSemiBold
                                                />
                                            </WithStatusDot>
                                        }
                                        secondCellContent={
                                            <StyledTitle regular>
                                                {title}
                                            </StyledTitle>
                                        }
                                        forthCellContent={
                                            <UnderlinedLink
                                                text={translate('edit')}
                                                url={getRouteWithPractitionerId(
                                                    Routes.practitionersEdit,
                                                    practitioner.id,
                                                    id
                                                )}
                                                rightAlign="right"
                                            />
                                        }
                                        marginTop={1}
                                    />
                                </Box>
                            )
                        })}
                    </TableContentContainer>
                </TableContainer>
            )}
        </SidebarLayout>
    )
}

export default withConnectedStore(Practitioners)
