import React from 'react'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Row } from '@shared/components/Table/Raw'
import { translate } from '@utils/translationUtils'
import { getRouteWithTreatmentId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { StyledTitle } from '@containers/clinics/styledComponents'
import { Title } from '@containers/clinics/components/Title'

export const TreatmentsContent = ({ treatments, clinicId }) => {
    return treatments.map(({ price, title, id }) => (
        <Row
            key={id}
            firstCellContent={<Title text={title} isSemiBold />}
            thirdCellContent={
                <StyledTitle regular right>
                    {`${price} kroner`}
                </StyledTitle>
            }
            forthCellContent={
                <UnderlinedLink
                    text={translate('edit')}
                    url={getRouteWithTreatmentId(
                        Routes.treatmentsEdit,
                        id,
                        clinicId
                    )}
                    rightAlign="right"
                />
            }
            marginTop={1}
        />
    ))
}
