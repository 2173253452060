import React from 'react'
import { TableColumn } from './styledComponents'
import Box from '@material-ui/core/Box'

export const Row = ({
    marginTop = 0,
    firstCellContent,
    secondCellContent,
    thirdCellContent,
    forthCellContent,
}) => (
    <Box display="flex" alignItems="center" width="100%" mt={marginTop}>
        <TableColumn width={40}>{firstCellContent}</TableColumn>
        <TableColumn width={25}>{secondCellContent}</TableColumn>
        <TableColumn width={25}>{thirdCellContent}</TableColumn>
        <TableColumn width={10}>{forthCellContent}</TableColumn>
    </Box>
)
