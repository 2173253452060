const isDevelopmentMode = process.env.NODE_ENV === 'development'
const environment = process.env.REACT_APP_ENVIRONMENT;

let API_URL;
if (isDevelopmentMode) {
    API_URL = 'http://localhost:8000/api';
} else {
    if (environment === 'staging'){
        API_URL = 'https://api.staging.booking.dentalmedia.io/api';
    } else {
        API_URL = 'https://api.booking.dentalmedia.io/api';
    }
}


export const envConfig = {
    API_URL: API_URL
}
