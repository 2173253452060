import React, { useEffect } from 'react'
import { SidebarLayout } from '@shared/components/Sidebar/SidebarLayout'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import { TableContainer } from '@shared/components/Table/styledComponents'
import { Row } from '@shared/components/Table/Raw'
import { Title } from '@containers/clinics/components/Title'
import { UnderlinedLink } from '@shared/components/Links/UnderlinedLink'
import { Box, AccordionSummary } from '@material-ui/core'
import { Routes } from '@shared/constants/routes'
import { getRouteWithId } from '@utils/routeUtils'
import { useParams } from 'react-router'
import { getClinicTreatments } from '@store/reducers/treatments'
import { getKey } from '@utils/arrayUtils'
import { TreatmentsContent } from './components/TreatmentsContent'

const Treatments = ({ store, dispatch }) => {
    const { id } = useParams()
    const { treatments, loading } = store.treatments

    useEffect(() => {
        dispatch(getClinicTreatments(id))
    }, [])

    return (
        <SidebarLayout loading={loading}>
            <TableContainer>
                <Box mb={3}>
                    <AccordionSummary expandIcon={null}>
                        <Row
                            firstCellContent={
                                <Title
                                    text={translate('treatment.treatments')}
                                />
                            }
                            thirdCellContent={
                                <UnderlinedLink
                                    text={translate('treatment.editCategories')}
                                    url={getRouteWithId(
                                        Routes.treatmentsCategories,
                                        id
                                    )}
                                    rightAlign="right"
                                />
                            }
                            forthCellContent={
                                <UnderlinedLink
                                    text={translate('addNew')}
                                    url={getRouteWithId(
                                        Routes.treatmentsCreate,
                                        id
                                    )}
                                    rightAlign="right"
                                />
                            }
                            marginTop={1}
                        />
                    </AccordionSummary>

                    {!!treatments.length &&
                        treatments.map(treatment => {
                            const { name, treatments } = treatment

                            if (treatments?.length) {
                                return (
                                    <Box
                                        key={getKey()}
                                        width="100%"
                                        marginBottom={2}
                                        marginTop={2}
                                    >
                                        <Row
                                            firstCellContent={
                                                <Title text={name} />
                                            }
                                            marginTop={1}
                                        />

                                        <TreatmentsContent
                                            {...{
                                                treatments,
                                                clinicId: id,
                                            }}
                                        />
                                    </Box>
                                )
                            }

                            return null
                        })}
                </Box>
            </TableContainer>
        </SidebarLayout>
    )
}

export default withConnectedStore(Treatments)
