import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { AuthorizationProvider } from '@utils/authorizationUtils'
import { baseActions } from '@store/baseActions'
import { PractitionersUrlProvider } from '@store/apiUrlProviders/PractitionersUrlProvider'
import { getFormData } from '@utils/requestUtils'
import { baseReducer } from '@store/baseReducers'

const practitionersUrlProvider = new PractitionersUrlProvider()
const authorizationProvider = new AuthorizationProvider()

const initialState = {
    loading: false,
    practitioners: [],
    practitionerDetails: {},
}

// ACTIONS
export const getPractitioners = createAction(
    'GET_PRACTITIONERS',
    clinicId => ({
        payload: Axios.get(
            practitionersUrlProvider.practitionersUrl(clinicId),
            authorizationProvider.getAuthHeaders()
        ),
    })
)

export const createPractitioner = createAction(
    'CREATE_PRACTITIONER',
    (data, clinicId) => {
        const formData = getFormData(data)

        return {
            payload: Axios.post(
                practitionersUrlProvider.practitionersUrl(clinicId),
                formData,
                authorizationProvider.getAuthHeaders()
            ),
        }
    }
)

export const updatePractitioner = createAction(
    'UPDATE_PRACTITIONER',
    (data, id) => {
        const formData = getFormData(data)

        return {
            payload: Axios.post(
                practitionersUrlProvider.practitionerDetailsUrl(id),
                formData,
                authorizationProvider.getAuthHeaders()
            ),
        }
    }
)

export const getPractitionerDetails = createAction(
    'GET_PRACTITIONER_DETAILS',
    id => ({
        payload: Axios.get(
            practitionersUrlProvider.practitionerDetailsUrl(id),
            authorizationProvider.getAuthHeaders()
        ),
    })
)

export const deletePractitioner = createAction('DELETE_PRACTITIONER', id => ({
    payload: Axios.delete(
        practitionersUrlProvider.practitionerDetailsUrl(id),
        authorizationProvider.getAuthHeaders()
    ),
}))

// REDUCERS
const reducer = {
    [createPractitioner]: baseReducer,
    [updatePractitioner]: baseReducer,
    [deletePractitioner]: baseReducer,
    [getPractitioners]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            practitioners: payload.data,
            loading: false,
        }),
    },
    [getPractitionerDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            practitionerDetails: payload.data,
            loading: false,
        }),
    },
}

export default typeToReducer(reducer, initialState)
